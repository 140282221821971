<template>
  <component :is="item === undefined ? 'div' : 'b-card'">
    <b-overlay
      :show="item === null"
      rounded="sm"
    >
      <div v-if="item">
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                :label="$t('admin.labels.indexing')"
                label-for="indexing_status"
                :state="errors && errors.indexing_status ? false : null"
              >
                <v-select
                  id="indexing_status"
                  v-model="item.indexing_status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="indexing_status"
                />
                <b-form-invalid-feedback v-if="errors && errors.indexing_status">
                  {{ errors.indexing_status[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="8"
            >
              <b-form-group
                :label="$t('admin.labels.slug')"
                label-for="slug"
              >
                <b-form-input
                  id="slug"
                  v-model="item.slug"
                  :state="errors && errors.slug ? false : null"
                />

                <b-form-invalid-feedback v-if="errors && errors.slug">
                  {{ errors.slug[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <br>
          <b-tabs>
            <b-tab
              v-for="language in languages"
              :key="language.code"
            >
              <template #title>
                <b-img
                  :src="require('@/assets/images/flags/' + language.code + '.svg')"
                  height="16"
                  width="16"
                  class="mr-1"
                />
                <span class="d-none d-sm-inline">{{ language.title }}</span>
              </template>
              <b-row>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('admin.labels.title')"
                    :label-for="'title.' + language.code"
                  >
                    <b-form-input
                      :id="'title.' + language.code"
                      v-model="item.title[language.code]"
                      :state="errors && errors['title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['title.' + language.code]">
                      {{ errors['title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('admin.labels.meta_h1')"
                    :label-for="'meta_h1.' + language.code"
                  >
                    <b-form-input
                      :id="'meta_h1.' + language.code"
                      v-model="item.meta_h1[language.code]"
                      :state="errors && errors['meta_h1.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_h1.' + language.code]">
                      {{ errors['meta_h1.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('admin.labels.meta_title')"
                    :label-for="'meta_title.' + language.code"
                  >
                    <b-form-input
                      :id="'meta_title.' + language.code"
                      v-model="item.meta_title[language.code]"
                      :state="errors && errors['meta_title.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_title.' + language.code]">
                      {{ errors['meta_title.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('admin.labels.meta_description')"
                    :label-for="'meta_description.' + language.code"
                  >
                    <b-form-textarea
                      :id="'meta_description.' + language.code"
                      v-model="item.meta_description[language.code]"
                      :state="errors && errors['meta_description.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_description.' + language.code]">
                      {{ errors['meta_description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('admin.labels.meta_keywords')"
                    :label-for="'meta_keywords.' + language.code"
                  >
                    <b-form-textarea
                      :id="'meta_keywords.' + language.code"
                      v-model="item.meta_keywords[language.code]"
                      :state="errors && errors['meta_keywords.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['meta_keywords.' + language.code]">
                      {{ errors['meta_keywords.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('admin.labels.canonical')"
                    :label-for="'canonical.' + language.code"
                  >
                    <b-form-input
                      :id="'canonical.' + language.code"
                      v-model="item.canonical[language.code]"
                      :state="errors && errors['canonical.' + language.code] ? false : null"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['canonical.' + language.code]">
                      {{ errors['canonical.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="24"
                  md="12"
                >
                  <b-form-group
                    :label="$t('admin.labels.description')"
                    :label-for="'description.' + language.code"
                    :state="errors && errors['description.' + language.code] ? false : null"
                  >
                    <quill-editor
                      v-if="item.template_type !== 'Html' && item.template_type !== 'Delivery'"
                      :id="'description.' + language.code"
                      v-model="item.description[language.code]"
                      :options="editorOptions"
                      :state="errors && errors['description.' + language.code] ? false : null"
                    />
                    <b-form-textarea
                      v-else
                      :id="'description.' + language.code"
                      v-model="item.description[language.code]"
                      :state="errors && errors['description.' + language.code] ? false : null"
                      style="height: 500px;"
                    />
                    <b-form-invalid-feedback v-if="errors && errors['description.' + language.code]">
                      {{ errors['description.' + language.code][0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>

          <!-- Action Buttons -->
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSubmit"
          >
            {{ $t('admin.buttons.save') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            :to="{ name: 'pages-index' }"
          >
            {{ $t('admin.buttons.cancel') }}
          </b-button>
        </b-form>
      </div>
    </b-overlay>
  </component>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import EditorMixin from '@/mixins/EditorMixin'

import router from '@/router'
import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin, EditorMixin],
  data() {
    return {
      item: null,
      languages: [],
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/languages')
      .then(response => {
        this.languages = response.data.data
      })

    this.item = await this.transformData()
  },
  methods: {
    async onSubmit() {
      const data = {
        title: this.item.title,
        description: this.item.description,
        slug: this.item.slug,
        meta_description: this.item.meta_description,
        meta_h1: this.item.meta_h1,
        meta_keywords: this.item.meta_keywords,
        meta_title: this.item.meta_title,
        canonical: this.item.canonical,
        indexing_status: this.item.indexing_status,
      }

      this.$http.post('/api/admin/pages', data)
        .then(() => {
          router.replace({ name: 'pages-index' })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    transformData() {
      const data = {
        title: {},
        description: {},
        slug: null,
        meta_description: {},
        meta_h1: {},
        meta_keywords: {},
        meta_title: {},
        canonical: {},
        indexing_status: 'enabled',
      }

      _.each(this.languages, language => {
        data.title[language.code] = null
        data.description[language.code] = null
        data.meta_description[language.code] = null
        data.meta_h1[language.code] = null
        data.meta_keywords[language.code] = null
        data.meta_title[language.code] = null
        data.canonical[language.code] = null
      })

      return data
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
